import React, { ReactElement, useEffect, useState, useCallback } from 'react'
import './PropertyContactForm.scss'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import Seo from '../../components/Seo/Seo'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const PropertyNavigationSliced = loadable(() =>
  import('../../components/PropertyNavigationSliced/PropertyNavigationSliced'),
)

type TowerTypes = {
  name: string
  locationLandmarks?: {
    locationBrief: string
    locationDefault: string
    locationSpecific: string
    latitude: string
    longitude: string
    landmarksEstablishment?: {
      items: {
        establishmentName: string
        establishmentBody: string
        establishmentDistance: string
        establishmentLocation: string
        imagesGallery: {
          description: string
          url: string
          featured: boolean
        }[]
        longitude: string
        latitude: string
      }[]
    }
  }
  overviewDetails: {
    panoramas?: {
      url: string
      featured: string
    }[]
  }
}

type PropertyDataTypes = {
  id: string
  originalId: string
  name: string
  overviewDefault: string
  slug: string
  logo: string
  projectName: string
  propertyPrice: string
  propertySizeFloorArea: string
  propertySizeLotArea: string
  propertyType: string
  propertyPriceRangeHighest: string
  propertyPriceRangeLowest: string
  image1: string
  image2: string
  image3: string
  towers: TowerTypes[]
  location: string
}

type PropertyContactFormPropTypes = {
  data: {
    propertyData: PropertyDataTypes
  }
}

const PropertyContactForm = ({
  data: { propertyData },
}: PropertyContactFormPropTypes): ReactElement => {
  const defaultProperty = {
    label: propertyData.projectName,
    value: propertyData.projectName,
  }

  const setPropertyName = useCallback(async (options, property) => {
    const setSelected = Array.from(options)?.forEach((el: any, i): any => {
      if (el.value === property.value) {
        const insertValue = document
          .getElementById('contact-form')
          ?.getElementsByTagName('select')
          .item(0)
          ?.getElementsByTagName('option')
          .item(i)
          ?.setAttribute('selected', 'true')
      }
    })
  }, [])

  const inputProperty = useCallback(async () => {
    window.addEventListener('message', (event) => {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormReady'
      ) {
        const options = document
          .getElementById('contact-form')
          ?.getElementsByTagName('select')
          .item(0)
          ?.getElementsByTagName('option')
        if (defaultProperty && options) {
          const found = Array.from(options).filter(
            (el) => el.value === defaultProperty.value,
          )
          if (found.length > 0) {
            setPropertyName(options, defaultProperty)
          } else {
            const setDefault = document
              .getElementById('contact-form')
              ?.getElementsByTagName('select')
              .item(0)
            if (setDefault) setDefault.selectedIndex = 0
          }
        }
      }
    })
  }, [defaultProperty, setPropertyName])

  useEffect(() => {
    if (window.hbspt?.forms) {
      window.jQuery =
        window.jQuery ||
        ((): { change(): null; trigger(): null } => ({
          change: (): null => null,
          trigger: (): null => null,
        }))

      window.hbspt.forms.create({
        portalId: '8605476',
        formId: '82e35df6-4d1c-4ace-8eae-fdb7f5d084e1',
        target: '#contact-form',
        onFormReady: () => {
          /* */
        },
      })
      inputProperty()
    }
  }, [inputProperty])

  const [mainTower] = useState<TowerTypes | null>(
    propertyData.towers ? propertyData.towers[0] || null : null,
  )
  const aerialView = mainTower?.overviewDetails?.panoramas
    ? mainTower?.overviewDetails?.panoramas[0]?.url
    : null

  return (
    <Layout>
      <Seo
        title={`${propertyData.name} | Contact Us`}
        jsonData={{
          '@type': 'Apartment',
          description: propertyData.overviewDefault,
        }}
      />

      <div className="property-contact-us">
        <div className="property-contact-us-navigation">
          <PropertyNavigationSliced
            propertyType={propertyData.propertyType}
            propertySlug={propertyData.slug}
            propertyLocation={propertyData.location}
          />
        </div>
        <div className="property-contact-us-content">
          <h2 className="title">Contact Us</h2>
          <h4 className="description">Inquiry Form</h4>
          <div id="contact-form" />
        </div>
      </div>
    </Layout>
  )
}

export default PropertyContactForm

export const pageQuery = graphql`
  query PropertyContactFormQuery($id: String!) {
    propertyData: property(id: { eq: $id }) {
      ...PropertyPageFields
    }
  }
`
